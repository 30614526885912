import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const opshubStandalonePlugin = createPlugin({
  id: 'opshub',
  routes: {
    root: rootRouteRef,
  },
});

export const OpshubPage = opshubStandalonePlugin.provide(
  createRoutableExtension({
    name: 'opshub',
    component: () => import('./components/Home/Home').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);

export const OpshubEventhubPage = opshubStandalonePlugin.provide(
  createRoutableExtension({
    name: 'eventhub',
    component: () => import('./components/Eventhub/OpshubEventhubPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);

export const OpshubAccessPage = opshubStandalonePlugin.provide(
  createRoutableExtension({
    name: 'access',
    component: () => import('./components/OpshubAccess/OpshubAccessPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
